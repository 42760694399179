@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

p {
  font-size: 1.1rem;
  text-align: center;
}

img {
  width: 6rem;
  @media only screen and (min-width: 940px) {
    width: 8rem;
  }
}