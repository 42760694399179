@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@800&family=Karla:wght@300;400;700&family=Roboto:wght@100;400;700&display=swap');
@import "./variables.modules";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Karla', 'Roboto', Helvetica, sans-serif;
    font-size: 1.5vh;
    width: 100%;
    height: 100%;
    color: $dark;
    background-color: $white;
    box-sizing: border-box;
    padding-top: env(safe-area-inset-top, 20px);  /* 20px fallback */
    padding-right: env(safe-area-inset-right, 10px); /* 10px fallback */
    padding-bottom: env(safe-area-inset-bottom, 20px); /* 20px fallback */
    padding-left: env(safe-area-inset-left, 10px);
}

h2, h3, h4 {
  font-size: 1.5rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
  }
}

button {
  font-family: "Karla";
  border: 1.5px solid $dark;
  background-color: $white;
  border-radius: 10px;
  font-size: 1rem;
  padding: .5rem 1.5rem;
}

li {
    color: $dark;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
}

.dark {
    background-color: $dark;
    color: $white;
    transition: all 400ms;
  
    a, i, li {
      color: $white;
      transition: color 400ms;
    }
  
    a:visited {
      color: $white;
    }
  
    button {
      color: $white;
      border-color: $white;
      background: $dark
    }
  
    button:hover {
      background: $white;
      color: $dark
    }
  
    footer {
      color: $white;
    }
  }
  
  .light {
    background-color: $white;
    color: $dark;
    transition: all 400ms;
  }